<style lang="scss" scoped>
.form-item-list{
  display: flex;
  border-bottom: 1px solid #E8E6E6;
  margin-bottom: 50px;
  .iconfont{
    cursor: pointer;
  }
  .phone-dropdown{
    width: 90px;
    position: relative;
    color: #999999;
    margin-right: 20px;
  }
  .phone-dropdown::after{
    content: '';
    width: 1px;
    height: 15px;
    background: #C1BDBD;
    position: absolute;
    right: 0;
    top: 10px;
  }
}
.form-item-title{
  font-size: 18px;
	font-weight: 600;

	letter-spacing: 0px;
	color: #666666;
}
.login-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;

  .login-left-img {
    width: 670px;
    height: 100%;
    background: #8375e7;
    overflow: hidden;
    border-radius: 0vw 3.13vw 3.13vw 0vw;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      width: 670px;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-right-content {
    height: 100%;
    flex: 1;
    min-width: 680px;
    position: relative;
    overflow: hidden;

    .content-box {
      width: 548px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .small-element-ruler {
      position: absolute;
      top: 245px;
      left: 49px;
    }
  }
}

.content-box {

  .login-title {
    text-align: center;

    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #3e3e3e;
    margin-top: 35px;
  }

  .login-form {
    margin-top: 60px;
    position: relative;

    .small-element-block {
      position: absolute;
      top: 50px;
      left: -108px;
    }
  }
}

::v-deep {
  .el-input__inner {
    height: 34px;
    border: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;

  }

  .el-input__inner:focus {
    border: none;
    outline: none;
  }

  .el-form-item {
    // margin-bottom: 12px;
  }

  .el-input__inner::placeholder {
    color: #a2a2a2;
  }

  .el-button--primary {
    font-weight: 600;
    height: 65px;
    font-size: 18px;
    border: none;
    background: #6c4ecb;
    margin-top: 52px;
    border-radius: 10px;
  }
}
</style>

<template>
  <div class="login-container">
    <div class="login-left-img">
      <img src="../../assets/images/login-bg.png" alt="" />
    </div>
    <div class="login-right-content">
      <div class="small-element-ruler">
        <img src="../../assets/images/small-element-ruler.png" alt="" />
      </div>
      <div class="content-box">

        <div class="login-title">忘记密码</div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on"
          label-position="left">
          <div class="small-element-block">
            <img src="../../assets/images/small-element-block.png" alt="" />
          </div>
          <el-form-item>
            <div class="form-item-title">手机号</div>
            <div class="form-item-list">
              <el-dropdown class="phone-dropdown">
                <span class="el-dropdown-link">
                  +018
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>001</el-dropdown-item>
                  <el-dropdown-item>002</el-dropdown-item>
                  <el-dropdown-item>003</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-input ref="username" v-model="loginForm.phone" placeholder="注册时填写的手机号" name="phone" type="text"
                tabindex="1" autocomplete="on" />
            </div>

          </el-form-item>

          <el-form-item>
            <div class="form-item-title">短信验证码</div>
            <div class="form-item-list">
              <el-input ref="password" v-model="loginForm.code" type="password" placeholder="请输入密码" name="code"
              tabindex="2" autocomplete="on" />

              <el-button type="text">获取验证码</el-button>

            </div>

           
          </el-form-item>

          <el-form-item>
            <div class="form-item-title">设置密码</div>
            <div class="form-item-list">
              <el-input ref="password" v-model="loginForm.password" :type="passwordFlog?'password':'text'" placeholder="请输入密码" name="password"
              tabindex="2" autocomplete="on" />
              <i class="iconfont" :class="passwordFlog?'icon-biyanjing':'icon-yanjing'" @click="passwordFlog=!passwordFlog"></i>
            </div>

           
          </el-form-item>


          <el-button :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px"
            @click.native.prevent="handleLogin">确定修改</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@api/user";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      loginForm: {
        phone: "",
        code:'',
        password: "",
      },
    
      loginRules: {
        phone: [{ required: true, trigger: "blur" }],
        code: [{ required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },

      loading: false,

      // 密码
      passwordFlog:true,


      // o: [],
    };
  },

  watch: {},

  created() { },

  mounted() {
    //username、password 为空时，获取焦点
    
    if (this.loginForm.username == "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password == "") {
      this.$refs.password.focus();
    }

    let JSON = require("@/assets/data/sss.JSON");

    // this.handeldg(JSON, 110000);

  },

  methods: {
    /**
     * 用户登录
     * post 请求例子
     */
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (!valid) return false;
        // const { data: resData } = await login();
        // if (resData.status != 1) return;
        sessionStorage.setItem("Token", "resData.data.token");
        this.$notify({
          title: "登录成功",
          message: "这是一条成功的提示消息",
          type: "success",
          duration: 1000,
        });
        // this.$router.push({
        //   name: "HOME",
        // });
      });
    },


    /**
     * 用户登录
     * post 请求例子
     */

    // handeldg(json, id) {
    //   let this_ = this;
    //   json.forEach(function (item) {
    //     if (item.code.slice(-2) != '00') {
    //       this_.o.push(item.code)
    //     } else {
    //       console.log(this_)
    //       this_.handeldg(item.children, item.code);
    //     }
    //   });
    //   return this_.o;
    // }

  },
};
</script>
